<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
                homeBlog:{}
            }
		},
		async mounted() {
			await this.ready();
            this.$set(this.$root, 'page', this);
            SEMICOLON.slider.init()
            this.getBlog()
		},
        methods: {
            getBlog() {
                Gen.apirest("/home-blog",this.myParams,(err,resp)=>{
                    if(err) console.log(err)
                    this.homeBlog = resp.data
                })
            },
        },
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element dark swiper_wrapper full-screen slider-parallax clearfix">
            <div class="slider-parallax-inner">
                <div class="swiper-container swiper-parent">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide dark" :style="'background: url('+baseUrl+'/frontend/images/flag-hero.jpg) no-repeat center center; background-size: cover;'">
                            <div class="container clearfix">
                                <div class="slider-caption">
                                    <h2 class="nott" data-animate="fadeInUp">Berperan aktif untuk peningkatan kualitas pendidikan serta
                                    implementasi dan pencapaian Tujuan Pembangunan Berkelanjutan (SDG) di Indonesia.</h2>
                                </div>
                            </div>
                            <div class="video-wrap">
                                <div class="video-overlay bg_gradient_hero" style="background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);
                                background: linear-gradient(to right, rgba(8, 8, 8, 0.34), rgba(211, 204, 227, 0));"></div>
                                <div class="video-overlay" style="z-index: 1;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="content" style="overflow: visible">
            <div class="content-wrap p-0">
                <div class="section bg_fff nobottommargin">
                    <div class="container clearfix">
                        <div class="row justify-content-center">
                            <div class="col-md-8 center">
                                <div class="heading_section">
                                    <h2>Tentang Kami</h2>
                                </div>
                                <div class="about_desc">
                                    <p>Sumber daya manusia yang berkualitas dan semakin berkembang maju di Indonesia merupakan harapan besar Yayasan kami yang dapat diwujudkan melalui akses pendidikan yang bermutu, kemampuan ekonomi masyarakat serta pemberdayaan, kualitas kesehatan dan lingkungan hidup, serta upaya advokasi maupun penelitian dan bantuan teknis lainnya.</p>
                                    <p>Yayasan kami mendukung penuh pemerintah Indonesia dan para pemangku kepentingan lainnya agar dapat berkolaborasi untuk mencapai Tujuan Pembangunan Berkelanjutan (Sustainable Development Goals).</p>
                                    <p>
                                        <router-link :to="{name:'ProfilYayasan'}" class="btn_green">Baca Selengkapnya</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap_ilustrator_right">
                    	<VImg :src="baseUrl+'/frontend/images/book_ilus.png'"></VImg>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="section  notopmargin nobottommargin">
                    <div class="container clearfix">
                        <div class="row justify-content-center">
                            <div class="col-md-10 center">
                                <div class="heading_section">
                                    <h2>Berita</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-10" v-for="(v,k) in homeBlog" :key="k">
                                <div class="news_wrapper">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="news_thumb">
                                                <router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
                                                    <img :src="uploader(v.image)" :alt="v.title">
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="news_content_post">
                                                <h3>
                                                    <router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
                                                        {{ v.title }}
                                                    </router-link>
                                                </h3>
                                                <p v-html="v.excerpt"></p>
                                                <ul>
                                                    <li>{{(v.publish_date||"").dates("format")}}</li>
                                                    <li>{{v.author}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <router-link :to="{name:'DetailBlog', params:{slug:v.slug}}" class="btn_more">
                                                <i class="icon-angle-right"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
	                        <div class="col-md-10" v-if="homeBlog.length == 3">
	                            <p class="text-center">
                                    <router-link :to="{name:'News'}" class="btn_green">
                                        {{ web.btn_lihat_lain }}
                                    </router-link>
                                </p>
	                        </div>
	                    </div>
	                </div>
	            </div>
		    </div>
		</section>
	</div>
</template>